<template>
  <div>
    <div class="page-title pageheading" style="height:750px;overflow: auto;">
      <div class="container">
        <div class="row">
            
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-xl">
                <div  class="list-group text-left" id="list-tab" role="tablist">
                  <h3 class="titlename">
                    <i class="fas fa-user"></i><span class="ml-3">Mailbox</span>
                  </h3>
                  <br />
                  <a
                    style="word-break: break-all;"
                    v-for="(email, ndxA03) in emailTempsList"
                    :key="ndxA03"
                    @click="emailTempsListClick(email)"
                    :class="selectedEmailTempClass(email)"
                    role="tab"
                    aria-selected="true"
                    ><i :class="selectedEmailTempIcon(email)"></i
                    >{{ email.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-8 col-md-8">
            <form class="">
              <span class="ml-3" style="font-size: 20px"
                >Compose New Message</span
              >
              <div class="card-body">
                <div class="form-group">
                  <h2 class="form-text">Send To.</h2>
                  <select
                    name="SendTo"
                    placeholder="Send To"
                    class="form-control form-control-solid form-control-lg"
                    v-model="emailCompose.emailSend"
                    :disabled="emailCompose.emailSendIndvidual != ''"
                  >
                    <option value="" selected>Select group</option>
                    <option value="Client">All Clients</option>
                    <option value="Care">All Caregivers</option>
                    <option value="Both">Both</option>
                  </select>
                </div>

                <v-text-field
                  label="Send To (Individual):"
                  v-model="emailCompose.emailSendIndvidual"
                  maxlength="40"
                 :disabled="emailCompose.emailSend != ''"
                >
                </v-text-field>

                <v-text-field
                  label="Subject:"
                  v-model="emailCompose.emailSubject"
                  maxlength="100"
                >
                </v-text-field>

                <h4 class="">Email Text:</h4>
                <div id="fixed">
                  <vue-editor class="mt-3" v-model="emailCompose.emailContact">
                  </vue-editor>
                </div>

                <b-button
                  size="lg"
                  variant="success"
                  style="margin-top: 10px"
                  @click="sendEmail(emailCompose)"
                >
                  Send Email
                  <span v-if="isSending">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Swal from "sweetalert2";

export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      isSending: false,
      emailTempsList: [],

      emailCompose: {
        emailName: "",
        emailSubject: "",
        emailContact: "",
        emailSend: "",
        emailSendIndvidual: "",
      },
    };
  },
  methods: {
    selectedEmailTempIcon(email) {
      if (email.id == 0) return "fas fa-envelope mr-3";
      else return "far fa-file-alt mr-3";
    },
    selectedEmailTempClass() {
      return "list-group-item list-group-item-action active show";
    },
    emailTempsListClick(email) {
      if (email.emailSubject != "") {
        this.emailCompose = {
          emailName: email.name,
          emailSubject: email.emailSubject,
          emailContact: email.emailText,
          emailSendIndvidual: this.emailCompose.emailSendIndvidual,
          emailSend: this.emailCompose.emailSend

        };
      } else {
        this.emailCompose = {
          emailName: "",
          emailSubject: "",
          emailContact: "",
          emailSend: this.emailCompose.emailSend,
          emailSendIndvidual: this.emailCompose.emailSendIndvidual,
        };
      }
    },
    //Send Email
    async sendEmail(emailChk) {
      //validation
       if(emailChk.emailContact == ""){ 
         this.ShowWarning("Email Text is require!"); 
         event.preventDefault();
         return;
      } else if(emailChk.emailSubject == ""){ 
         this.ShowWarning("Email Subject is require!"); 
         event.preventDefault();
         return;
      } else if(emailChk.emailSendIndvidual == "" && emailChk.emailSend == ""){ 
         this.ShowWarning("Email is require!"); 
         event.preventDefault();
         return;
      } else if (emailChk.emailSendIndvidual.length > 0) {
        if (emailChk.emailSendIndvidual != null) {
          if (!this.validEmail(emailChk.emailSendIndvidual)) {
            this.Validity(this.e);
          }
        }
      }

      this.isSending = true;
      await this.$store
        .dispatch("postSendEmail", this.emailCompose)
        .then((response) => {
          this.isSending = false;
          if (response.message == "Success") {
            this.emailCompose = {
              emailName: "",
              emailSubject: "",
              emailContact: "",
              emailSend: "",
              emailSendIndvidual: "",
            };
            Swal.fire({
              title: "",
              text: "This email has been sent successfully.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
    async fetchData() {
      await this.$store
        .dispatch("getEmailList", {})
        .then((response) => {
          if (response.message == "Success") {
            this.emailTempsList = response.data;
            this.emailTempsList.splice(0, 0, {
              id: "",
              name: "Compose",
              emailSubject: "",
              emailText: "",
              isActive: "",
              isDeleted: "",
            });
          }
        })
        .catch((ex) => {
          this.isfetching = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          });
        });
    },
    ShowWarning(msg) {
      Swal.fire({
        title: "Warning",
        text: msg,
      });
    },
    validEmail(email) {
      if (email == "") {
        return true;
      }
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    Validity(e) {
      this.ShowWarning("Valid email required");
      e.preventDefault();
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
  },
};
</script>

<style scoped>
#size-fixed {
   height:100px;
   max-height: 100px;
}
#fixed{
    width:750px;
    max-width: 750px;
    height:270px;
    overflow:scroll;
    }
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item-action {
  width: 100%;
  color: #4f5467;
  text-align: inherit;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item.active:hover {
  background: #1b5d77 !important;
  border-color: #1b5d77 !important;
  color: black;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.row {
  margin: 0px !important;
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
